import React from "react";
import { useMediaQuery } from "react-responsive";
import Wallet from '..//Assets/wallet.png';
import WalletLg from '..//Assets/walletLg.png';
import ImageTwo from '..//Assets/transaction-img2.png';
import ImageTwoLg from '..//Assets/transaction-img2Lg.png';
import ImageThree from '..//Assets/transaction-img3.png';
import ImageThreeLg from '..//Assets/transaction-img3Lg.png';

const Transactions = () => {
    const isLargeScreen = useMediaQuery({ minWidth: 1024 });

  return (
    <div>
      <section className="pt-12 lg:pt-[6.5rem]">
        <h1 className="text-center text-black200 font-inter font-semibold text-2xl tracking-[-2px] leading-[150%] mb-4 lg:text-[45px] lg:w-[579px] lg:m-auto lg:mb-5">
          Easily connect with clients or customers
        </h1>
        <p className="text-center text-gray-600 font-inter font-normal leading-[150%] text-sm px-8 lg:text-lg lg:w-[482px] lg:m-auto">
          Our virtual transaction mechanism offers a range of benefits for
          businesses and individuals
        </p>

        <article className="px-6 mt-10 flex flex-col gap-6 sm:mx-[5rem] md:mx-0 md:flex-row lg:flex-wrap lg:flex-row lg:gap-10 lg:justify-center  2xl:gap-[13.8rem] 3xl:flex-nowrap 3xl:gap-[2rem] 6xl:gap-[15rem]">
          <div className="rounded-md bg-gray-100 px-5 py-5 lg:rounded-3xl lg:pt-12">
           <p className="text-black text-sm font-bold leading-[28.3px] tracking-[-0.265px] mb-3 lg:mb-12 lg:w-[310px] lg:pl-5 lg:text-[21px]">
           Support for <span className="bg-Yellow lg:bg-inherit">multiple <span className="lg:bg-Yellow">currencies and transfer</span> </span>methods around the Globe
           </p>
           <img src={isLargeScreen ? WalletLg : Wallet} alt="Multiple Currency" className="rounded-md" />
          </div>

          <div className="rounded-md bg-gray-100 px-5 py-5 lg:rounded-3xl lg:pt-12">
           <p className="text-black text-sm font-bold leading-[28.3px] tracking-[-0.265px] mb-3 lg:mb-12 lg:pl-5 lg:text-[21px] lg:w-[317px]">
           Latest <span className="bg-Yellow lg:bg-inherit"><span className="lg:bg-Yellow">encryption and security</span> protocols</span> for secure and reliable fund transfer process
           </p>
           <img src={isLargeScreen ? ImageTwoLg : ImageTwo} alt="Man typing" className="rounded-md" />
          </div>

          <div className="rounded-md bg-gray-100 px-5 py-5 lg:rounded-3xl lg:pt-12">
           <p className="text-black text-sm font-bold leading-[28.3px] tracking-[-0.265px] mb-3 lg:mb-12 lg:pl-5 lg:text-[21px] lg:w-[314px]">
           <span className="bg-Yellow">Low fees and competitive</span> exchange rates: affordable and accessible to everyone.
           </p>
           <img src={isLargeScreen ? ImageThreeLg : ImageThree} alt="Woman using a laptop" className="rounded-md" />
          </div>
        </article>
      </section>
    </div>
  );
};

export default Transactions;
