import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
// import Contact from "./Pages/Contact";
import FAQ from "./Pages/FAQ";
import Support from "./Pages/Support";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          {/* <Route path="/contact-us" element={<Contact />} /> */}
          <Route path="/faq" element={<FAQ />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy/policy" element={<PrivacyPolicy />} />
          {/* <Route path="/" */}

        </Routes>
      </Router>
    </>
  );
}

export default App;
