import { useState } from "react";

const Button = () => {
  // const [isSubmitted, setIsSubmitted] = useState(false);

  const [userData, setUserData] = useState({
    email: "",
  });

  let name, value;

  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    const { email } = userData;
    e.preventDefault();
    const option = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    };

    const result = await fetch(
      "https://liquidmoni-fe58b-default-rtdb.firebaseio.com/subscription-form.json",
      option
    );
    console.log(result);

    if (result.ok) {
      setUserData({
        email: "",
      });
    }
  };

  return (
    <>
      <form className="relative sm:ml-[6rem] sm:mr-[4rem] md:mr-[4rem] md:ml-[8rem] custom:mx-[9rem] lg:mx-0" onSubmit={handleSubmit}>
        <input
          type="email"
          onChange={(e) => handleInputChange(e)}
          name="email"
          value={userData.email}
          placeholder="Enter email address"
          className="rounded-lg border-2 border-solid border-[#A1A1AA] p-2 w-full placeholder:text-buttonColor placeholder:font-inter placeholder:pl-2 bg-transparent lg:p-4 lg:rounded-2xl xl:w-[476px] 2xl:w-[540px]"
        />
        <button className="absolute right-[.3rem] top-[.2rem] rounded-lg bg-buttonColor text-buttonFont p-2 font-inter text-sm font-bold w-[122px] md:right-[.5rem] lg:top-[.5rem] lg:py-2 lg:px-[.6] lg:w-[179px] lg:text-lg  xl:right-[8.8rem] 2xl:right-[2rem] 3xl:right-[4.6rem] hover:bg-Purple"
        type="submit">
          Get Notified
        </button>
      </form>
    </>
  );
};

export default Button;
