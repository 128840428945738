import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";

const PrivacyPolicy = () => {
  return (
    <main className="bg-cream">
      <NavBar />
      <section className="pt-20 lg:pt-[10rem] lg:px-[6rem]">
        <h1 className="text-black200 font-inter text-[20px] md:text-[40px] font-bold leading-[120%] tracking-[-1.6px] mb-[1.5rem]">
          <span className="text-Purple font-bold">Privacy Policy</span>
        </h1>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px] mb-[2rem]">
          This <span className="text-Purple font-bold">Privacy Policy</span>{" "}
          explains how personal information is collected, used, stored, and
          disclosed by Liquidmoni. This{" "}
          <span className="text-Purple font-bold">Privacy Policy</span> applies
          to our website and all related sites, applications, services, and
          tools, regardless of how you access or use them. This{" "}
          <span className="text-Purple font-bold">Privacy Policy</span>
          {""}
          is part of our Terms of Use. By accessing or using our Services,
          website, software, services, applications, products, and content
          offered via Liquidmoni (collectively, the “Services”), you agree to
          this <span className="text-Purple font-bold">Privacy Policy</span> and
          our Terms of Use.
        </p>

        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          This <span className="text-Purple font-bold">Privacy Policy</span>{" "}
          applies to your use of (regardless of your means of accessing) our
          Services. You may access or use our Services through a desktop,
          laptop, mobile phone, tablet, or other consumer electronic device
          (each, a "Device"). This{" "}
          <span className="text-Purple font-bold">Privacy Policy</span> does not
          apply to services that are not owned or controlled by Liquidmoni,
          including third-party websites and the services of other Liquidmoni
          merchants. These other sites may place their own cookies, plug-ins, or
          other files on your computer, collect data, or solicit personal
          information from you. Liquidmoni does not control these third-party
          websites, and we are not responsible for their privacy statements.
          Please consult such third parties’ privacy statements.
        </p>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          information we collect
        </h2>

        <p className="mt-[2rem] text-neutralGray font-inter text-lg font-normal leading-[27px]">
          <span className="mr-[1rem]">1.</span>Information You Provide Us: To
          gain full access to our website and services, you must register for a
          Liquidmoni account. When you register for an account, we collect
          Personal Information which you voluntarily provide to us. Personal
          Information refers to information relating to an identified person or
          information that can be used to identify you, (e.g. email address,
          bank details, name, telephone number). It may also include anonymous
          information that may be linked to you specifically, (e.g. IP address).
        </p>

        <p className="mt-[2rem] text-neutralGray font-inter text-lg font-normal leading-[27px]">
          The Personal Information we have about you is directly made available
          to us when you:
        </p>
        <ul className="list-disc list-inside text-neutralGray font-inter text-lg font-normal leading-[27px]">
          <li>Sign up for a Liquidmoni Account.</li>
          <li>Use any of our services.</li>
          <li>Contact us or our customer support team;</li>
          <li>Fill our online forms</li>
          <li>Personal information</li>
        </ul>

        <h3 className="text-neutralGray font-inter text-lg font-bold leading-[150%] mt-[2rem]">
          We May Collect About You
        </h3>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          We may collect, use, process, store, or transfer personal information,
          such as:
        </p>
        <ul className="list-disc list-inside text-neutralGray font-inter text-lg font-normal leading-[27px]">
          <li>
            <span className="text-neutralGray font-inter text-lg font-bold leading-[150%]">
              Identity Data & Identification Documents:
            </span>{" "}
            Information such as, your full name(s), your government-issued
            identity number, bank verification number (BVN) or NIN, and your
            date of birth. Identification documents may include a passport or
            any Government-issued identity card, a photograph (if applicable)
            and any other registration information you may provide to prove you
            are eligible to use our services. This data is to enable us to
            verify your identity in order to offer our services to you;
          </li>
          <li>
            <span className="text-neutralGray font-inter text-lg font-bold leading-[150%]">
              Contact Data:{" "}
            </span>
            This is data that is needed to reach out to you, such as your
            contact address, email address, telephone number, details of the
            device you use and billing details;
          </li>
          <li>
            <span className="text-neutralGray font-inter text-lg font-bold leading-[150%]">
              Log/Technical information:{" "}
            </span>
            When you access Liquidmoni’s services, our servers automatically
            record information that your browser sends whenever you visit a
            website, links you have clicked on, length of visit on certain
            pages, unique device identifier, log-in information, location and
            other device details.
          </li>
          <li>
            <span className="text-neutralGray font-inter text-lg font-bold leading-[150%]">
              Financial Data:{" "}
            </span>
            Information, such as personal account number, the merchant’s name
            and location, the date and the total amount of transaction, and
            other information provided by financial institutions or merchants
            when we act on their behalf;
          </li>
          <li>
            <span className="text-neutralGray font-inter text-lg font-bold leading-[150%]">
              Transactional Data:{" "}
            </span>
            These are information relating to payments when you as a merchant
            (using one or more of our payment processing services) or as a
            customer, are using our products or services;
          </li>
          <li>
            Marketing and Communications Data: This includes both a record of
            your decision to subscribe or to withdraw from receiving marketing
            materials from us or from our third parties.
          </li>
          <li>
            Records of your discussions with us, if we contact you and if you
            contact us.
          </li>
        </ul>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          how we may use your personal information
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px] mt-[2rem]">
          We may use the Personal Information we collect to:
        </p>
        <ul className="list-disc list-inside text-neutralGray font-inter text-lg font-normal leading-[27px]">
          <li>
            Create and manage any accounts you may have with us, verify your
            identity, provide our services, and respond to your inquiries;
          </li>
          <li>
            To administer Liquidmoni(i.e. to provide our Services to you) and
            for internal operations, including troubleshooting, data analysis,
            testing, research, statistical and survey purposes (i.e. to
            guarantee Liquidmoni’s stability and security) and to solicit your
            feedback
          </li>
          <li>
            Process your payment transactions (including authorization,
            clearing, chargebacks and other related dispute resolution
            activities);
          </li>
          <li>
            Protect against and prevent fraud, unauthorized transactions, claims
            and other liabilities
          </li>
          <li>
            Provide, administer and communicate with you about products,
            services, offers, programs and promotions of Liquidmoni, financial
            institutions, merchants and partners;
          </li>
          <li>
            Evaluate and improve our business, including developing new products
            and services;
          </li>
          <li>
            As necessary to establish, exercise and defend legal rights; As may
            be required by applicable laws and regulations, including for
            compliance with Know Your Customers and risk assessment, Anti-Money
            Laundering, anti-corruption and sanctions screening requirements, or
            as requested by any judicial process, law enforcement or
            governmental agency having or claiming jurisdiction over Aboki
            Africa or it’s affiliates;
          </li>
          <li>
            To use data analytics to improve our Website, products or services,
            and user experiences.
          </li>
          <li>
            For other purposes for which we provide specific notice at the time
            of collection.
          </li>
        </ul>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          How we share your personal data
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          We may disclose or share your Personal Information with third parties
          which include our affiliates, employees, officers, service providers,
          agents, suppliers, subcontractors as may be reasonably necessary for
          the purposes set out in this policy.
        </p>

        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px] mt-[2rem]">
          Liquidmoni only shares personal information with External Third
          parties in the following limited circumstances:
        </p>
        <ul className="list-disc list-inside text-neutralGray font-inter text-lg font-normal leading-[27px]">
          <li>
            We have your consent. We require opt-in consent for the sharing of
            any personal information; We share Personal Information with third
            parties directly authorized by you to receive Personal Information,
            such as when you authorize a third party application provider to
            access your account. The use of your Personal Information by an
            authorized third party is subject to the third party's privacy
            policy and Liquidmoni shall bear no liability for any breach which
            may arise from such authorization by you.
          </li>
          <li>
            We provide such information to our subsidiaries, affiliated
            companies or other trusted businesses or persons for the purpose of
            processing personal information on our behalf. We require that these
            parties agree to process such information based on our instructions
            and in compliance with this{" "}
            <span className="text-Purple font-bold">Privacy Policy</span> and
            any other appropriate confidentiality and security measures
          </li>
          <li>
            We have a good faith belief that access, use, preservation or
            disclosure of such information is reasonably necessary to:
          </li>
          <li>
            Satisfy any applicable law, regulation, legal process or enforceable
            governmental request,
          </li>
          <li>
            Enforce applicable Terms of Service, including investigation of
            potential violations thereof
          </li>
          <li>
            Detect, prevent, or otherwise address fraud, security or technical
            issues, or
          </li>
          <li>
            Protect against imminent harm to the rights, property or safety of
            Liquidmoni, its users or the public as required or permitted by law
          </li>
          <li>
            If Liquidmoni becomes involved in a merger, acquisition, or any form
            of sale of some or all of its assets, we will provide notice before
            personal information is transferred and become subject to a
            different{" "}
            <span className="text-Purple font-bold">Privacy Policy</span>.
          </li>
        </ul>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          Other rights to your personal information with us
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          Below are the rights you have as a user in relation to your Personal
          Information;
        </p>
        <ol className="list-decimal list-inside text-neutralGray font-inter text-lg font-normal leading-[27px]">
          <li>
            Right to request access or copies to your Personal Information by
            contacting us
          </li>
          <li>
            Right to information on their personal information collected and
            stored;
          </li>
          <li>
            Right to objection or request for restriction; this means refrain us
            from doing certain things with your data or restrict the extent of
            our collection or processing of your data.
          </li>
          <li>
            Right to correct or rectify any Personal Information that you
            provide which may be incorrect, out of date or inaccurate.
          </li>
          <li>
            Right to object to the processing of your Personal Information for
            marketing purposes. You have a right to ask us not to contact you
            for marketing purposes by adjusting your notification preference on
            the settings page or by opting out via the unsubscribe link in
            marketing emails we send you.
          </li>
          <li>
            Right to request that Liquidmoni erase your Personal Information.
            You have the right to ask us to erase your Personal Information.
            Please note that this is a limited right that applies where the data
            is no longer required, or the processing has no legal justification.
            The exceptions to this right is where the applicable law requires us
            to retain a historical archive or where we retain a core set of your
            personal data to ensure we do not inadvertently contact you in
            future where you object to your data being used for marketing
            purposes.
          </li>
          <li>
            Right to object to decisions being taken by automated means which
            produce legal effects concerning you or similarly significantly
            affect you.
          </li>
          <li>
            Right to request the movement of data from Liquidmoni to a third
            party; this is the right to the portability of data;
          </li>
        </ol>

        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px] mt-[2rem]">
          The basis of we processing your Personal Information is your consent.
          You also have the choice at any time to withdraw consent which you
          have provided.
        </p>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px] mt-[2rem]">
          You may decline to provide your personal Information when it is
          requested by Liquidmoni, however, certain services or all the services
          may be unavailable to you. You may review your account settings and
          update your Personal Information directly or by contacting us. If you
          wish to exercise any of the rights set above, please contact us using
          the contact information provided in the Contact Us segment below.
        </p>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          Security and storage of your personal information
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          We have suitable security measures in place to prevent your Personal
          Information from being accidentally lost or used or accessed in an
          unauthorised way by a third party. When your bank account information
          is transmitted via our Services, it will be protected by encryption
          technology. In addition, we limit access to your personal data to
          those employees, agents, contractors and other third parties who have
          a business need to know. They will only process your Personal
          Information on our instructions. We have put in place procedures to
          deal with any suspected personal data breach and will notify you and
          any applicable regulator of a breach where we are legally required to
          do so.Unfortunately, no method of electronic transmission or storage
          via the Internet is 100% secure. Therefore, we cannot guarantee
          absolute security of your Personal Information. You also play a role
          in protecting your Personal Information. Please safeguard your
          password for your Liquidmoni account and do not share them with
          others. If we receive instructions using your Liquidmoni account login
          information, we will consider that you have authorized the
          instructions. You agree to notify us immediately of any unauthorized
          use of your Liquidmoni account or any other breach of security. We
          reserve the right, in our sole discretion, to refuse to provide our
          Services, terminate Liquidmoni accounts, and to remove or edit
          content.
          <p>
            Subject to applicable law, which might, from time to time, oblige us
            to store your Personal Information for a certain period of time, we
            will retain your Personal Information for as long as necessary to
            fulfil the purposes we collected it for, including the purposes of
            satisfying any legal, accounting, or reporting requirements.
          </p>
        </p>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          Cookies
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          Like many other websites, we use cookies to distinguish you from other
          users and to customize and improve our services. Cookies allow our
          servers to remember IP addresses, date and time of visits, monitor web
          traffic and prevent fraudulent activities. Our cookies never store
          personal or sensitive information; they simply hold a unique random
          reference to you so that once you visit the site we can recognize who
          you are and provide certain content to you. If your browser or browser
          add-on permits, you have the choice to disable cookies on our website,
          however this may impact your experience using our website. Unless you
          opt out of Cookies, we will assume you consent to the use of Cookies.
        </p>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          Age restriction
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          Our Services are not directed, or intended to be attractive, to
          children under the age of 18. We do not knowingly collect Personal
          Information from children under the age of 18. If you are under the
          Age Limit, please do not use the Services, and do not provide any
          personal data to us. If as a parent or guardian, you become aware that
          your child or ward child has provided us with any information without
          your consent, please contact us through the details provided in this
          {""}
          <span className="text-Purple font-bold">Privacy Policy</span>.
        </p>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          Opt-out rights
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          If you do not wish to receive offers or other notices from us in the
          future, you can "opt out" by contacting us as indicated at the end of
          this <span className="text-Purple font-bold">Privacy Policy</span> or
          by following the "unsubscribe" instructions in any communication you
          receive from us. Please be aware that if you are a User, you are not
          able to opt out of receiving communications about your Liquidmoni
          account or related transactions with us.
        </p>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          Change to our{" "}
          <span className="text-Purple font-bold">Privacy Policy</span>
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          Subject to applicable law, we may change, amend or review this Privacy
          Policy at any time to reflect new services or changes in our Policy.
          All changes made will be posted on this page and where changes will
          materially affect you, we will notify you of this change by placing a
          notice online or via mail. If you keep using our Services, you consent
          to all amendments of this{" "}
          <span className="text-Purple font-bold">Privacy Policy</span>.
        </p>

        <h2 className="text-neutralGray font-inter text-lg font-bold leading-[150%] uppercase mt-[3rem]">
          Contact us
        </h2>
        <p className="text-neutralGray font-inter text-lg font-normal leading-[27px]">
          If you have any questions, comments, or concerns with respect to your
          Personal Information or this{" "}
          <span className="text-Purple font-bold">Privacy Policy</span>, you may
          contact us by: reaching out to the customer service support available
          on the website.
        </p>
      </section>

      <Footer />
    </main>
  );
};

export default PrivacyPolicy;
