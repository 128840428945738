import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
// import { BsGithub } from "react-icons/bs";
import { NavLink, Link } from "react-router-dom";

const Footer = () => {
  const pageUp = () => {
    window.scrollTo({ top: (0, 0), behavior: 'smooth' });
  };

  return (
    <>
      <footer className="bg-black text-white px-6 pt-11 lg:pt-20 lg:px-[7rem] relative lg:mt-36 lg:pb-24 pb-20 2xl:px-[15rem]">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:mb-14">
          <div>
            <h1 className="font-medium text-xl lg:text-3xl lg:w-[405px] mb-5">
              Learn how to grow 💪your business with{" "}
              <span className="text-orange">Liquidmoni</span>
            </h1>
          </div>
          <hr className="bg-white lg:w-10/12 lg:absolute lg:bottom-[39rem] xl:bottom-[25rem] 2xl:w-4/5" />
          <div>
            <button className="mt-7 rounded-[60px] bg-white w-[184px] text-black p-4 font-extrabold text-sm font-jakarta leading-5 lg:mt-0 hover:bg-Purple hover:text-white">
            <Link to="https://play.google.com/store/apps/details?id=com.liquidmoni.liquidmoni" target="_blank"> 
             Download Now
              </Link>
            </button>
          </div>
        </div>

        <div className="flex flex-col xl:flex-row lg:flex-wrap xl:gap-[6rem] 6xl:gap-[35rem]">
          <div>
            <h2 className="text-base font-jakarta font-bold leading-6 mt-12 mb-5">
              About Liquidmoni
            </h2>
            <p className="text-justify text-sm font-jakarta font-normal leading-5 lg:w-[405px]">
              At Liquidmoni, we believe that online transactions should be
              seamless and secure. That's why we offer innovative solutions for
              those looking to enter the digital world of e-commerce. Our
              cutting-edge technology provides virtual transaction capabilities
              that are safe and efficient, giving your customers the best online
              shopping experience possible.
            </p>

            <ul className="flex flex-row gap-[1rem] mt-4">
              <NavLink to="https://twitter.com/Liquidmoniapp" target="_blank" className="hover:cursor-pointer hover:-translate-y-[3px]">
                <BsTwitter />
              </NavLink>
              <NavLink to="https://www.linkedin.com/company/liquidmoni/about/" target="_blank" className="hover:cursor-pointer hover:-translate-y-[3px]">
                <BsLinkedin />
              </NavLink>
              <NavLink to="https://instagram.com/liquidmoniapp?igshid=Y2IzZGU1MTFhOQ==" target="_blank" className="hover:cursor-pointer hover:-translate-y-[3px]">
                <BsInstagram />
              </NavLink>
              {/* <li>
                <BsGithub />
              </li> */}
            </ul>
          </div>

          <div className="mt-10 text-center lg:flex">
            <div className="lg:flex lg:flex-col">
                <h1 className="text-base font-jakarta font-bold leading-6 mb-5 hover:text-Purple">Company</h1>
                <NavLink to="/about-us" className="pr-4 lg:mb-2 text-sm leading-10 font-normal hover:text-Purple" onClick={pageUp}>About</NavLink>
                <NavLink to="" className="pr-4 lg:mb-2 text-sm leading-10 font-normal hover:text-Purple">Features</NavLink>
                <NavLink to="" className="pr-4 lg:mb-2 text-sm leading-10 font-normal hover:text-Purple">Works</NavLink>
                <NavLink to="" className="pr-4 text-sm leading-10 font-normal hover:text-Purple">Career</NavLink>
            </div>

            <div className="mt-3 lg:flex lg:flex-col lg:mt-0 lg:ml-32">
                <h1 className="text-base font-jakarta font-bold leading-6 mb-5">Help</h1>
                <NavLink to="" className="pr-4 lg:mb-2 text-sm leading-10 font-normal hover:text-Purple">Customer Support</NavLink>
                <NavLink to="" className="pr-4 lg:mb-2 text-sm leading-10 font-normal hover:text-Purple">Delivery Details</NavLink>
                <NavLink to="/privacy/policy" onClick={pageUp} className="pr-4 lg:mb-2 text-sm leading-10 font-normal hover:text-Purple">Terms & Conditions</NavLink>
                <NavLink to="/privacy/policy" className="pr-4 lg:mb-2 text-sm leading-10 font-normal hover:text-Purple" onClick={pageUp}>Privacy Policy</NavLink>
            </div>

            <div className="mt-3 lg:flex lg:flex-col lg:mt-0 lg:ml-32">
                <h1 className="text-base font-jakarta font-bold leading-6 mb-5">Resources</h1>
                {/* <NavLink to="" className="pr-4 lg:mb-2 text-sm leading-10 font-normal text-center">Free ebooks</NavLink>
                <NavLink to="" className="pr-4 lg:mb-2 text-sm leading-10 font-normal">Development Tutorial</NavLink> */}
                <NavLink to="" className="pr-4 lg:mb-2 text-sm leading-10 font-normal">How to - Blog</NavLink>
                {/* <NavLink to="" className="text-sm leading-10 font-normal">Youtube Playlist</NavLink> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
