import React from "react";
import NavBar from "../Components/NavBar";
import AboutHero from "../Components/About/AboutHero";
import Mission from "../Components/About/Mission";
// import TeamTable from "../Components/About/Table";
import Footer from "../Components/Footer";

const About = () => {
  return (
    <>
      <div className="bg-none lg:bg-aboutBackground">
        <NavBar />
        <AboutHero />
      </div>
      <Mission />
      {/* <TeamTable /> */}
      <Footer />
    </>
  );
};

export default About;
