import React from "react";
// import MissionLine from '..//../Assets/MissionLine.svg';
// import AboutLine from '..//../Assets/aboutMobileLine.svg';


const Mission = () => {
  return (
    <>
      <section className="mt-24 px-6 lg:px-24 2xl:px-[15rem] pb-16 md:pb-0">
        <article className="flex flex-col lg:flex-row lg:gap-[10rem] md:text-center lg:text-left xl:gap-[14rem] xxl:gap-[19rem] 2xl:gap-[27rem] 3xl:gap-[12rem] 6xl:gap-[30rem] 6xl:w-[1500px]">
          <div className="lg:w-[288px] lg:h-[100px]">
          <h1 className="text-2xl font-inter font-semibold tracking-[-0.96px] leading-[120%] text-left lg:text-[40px] lg:h-[100px] lg:w-[448px]">
            “We provide virtual transaction mechanism that meets your need”
          </h1>
          </div>
          <p className="text-sm font-inter font-normal leading-[150%] text-neutralGray mt-8  lg:w-[771px] xl:w-[670px] lg:mt-0 3xl:text-lg">
          Liquidmoni, We are an international payment gateway bridging gaps of international transaction between senders and receivers within remitting approval location. Our aim is to ensure fast, reliable and dependable currency conversion and transfer from liquid wallet to local bank account in local currency. Our financial, compliance and support team are a guarantee of dedication to ensure smooth service delivery and customer satisfaction. We have pride in a team of best in compliance, developers, finance, operation, support and marketing.
          </p>
        </article>

        <article className="bg-Purple text-white rounded-xl mt-16 sm:mt-32 mx-[2rem] sm:mx-[10rem] p-8  sm:p-16">
          <div className="text-center">
            <h2 className="text-2xl font-inter font-bold lg:mb-4 leading-[150%]">Our Mission</h2>
            <p className="lg:text-lg font-inter font-normal leading-7 mt-4 lg:mt-0 text-sm">To remain the effective option currency exchange available helping customers to cushion the art of money transportation across the globe, reliable and dependable payment system serving the customer at their convenient around the world achieving the utmost customer satisfaction.</p>
          </div>
        </article>

        {/* <article className="bg-Purple text-white rounded-xl mt-8 flex flex-col gap-[4rem] pt-[3.3rem] lg:pt-14 lg:px-11 lg:pb-9 xl:px-2 xl:flex-row xl:gap-[1rem] lg:mt-28 1xl:gap-[2rem] xxl:gap-[5rem]  2xl:px-[6rem] 2xl:gap-[8rem] 3xl:px-[2rem] 3xl:gap-[1rem] 6xl:gap-[10rem]" id="about_mission">
            <div className="pl-8 pr-6 xl:pl-[1rem] 1xl:pl-[2.5rem] xxl:pl-[4rem] 3xl:pl-[1rem] 6xl:pl-[6rem]">
                <h2 className="text-2xl font-inter font-bold lg:mb-4 leading-[150%]">Our Mission</h2>
                <p className="lg:text-lg font-inter font-normal leading-7 lg:w-[511px] mt-4 lg:mt-0 text-sm">To remain the effective option currency exchange available helping customers to cushion the art of money transportation across the globe, reliable and dependable payment system serving the customer at their convenient around the world achieving the utmost customer satisfaction.</p>
            </div>

            <img src={MissionLine} alt="Line" className="xl:block hidden xl:ml-[-2rem]"/>
            <img src={AboutLine} alt="Line" className="xl:hidden w-[95%] px-8"/>

            <div className="pl-8 pr-6 pb-10 lg:pb-0 xl:pl-0">
                <h2 className="text-2xl font-inter font-bold lg:mb-4 leading-[150%]">Our Vision</h2>
                <p className="lg:text-lg font-inter font-normal leading-7 lg:w-[533px] mt-4 lg:mt-0 text-sm">To remain the effective option currency exchange available helping customers to cushion the art of money transportation across the globe, reliable and dependable payment system serving the customer at their convenient around the world achieving the utmost customer satisfaction.</p>
            </div>

        </article> */}
      </section>
    </>
  );
};

export default Mission;
